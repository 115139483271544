<template>
  <div class="shopping-mall" :class="lang">
    <shop-header v-if="!fromListing || (fromListing && !$route.path.includes('/shopDetails'))"></shop-header>
    <router-view></router-view>
    <shop-footer v-if="!fromListing"></shop-footer>
    <gettingStarted ref="gettingStarted" v-if="!fromListing"></gettingStarted>
  </div>
</template>

<script>
import shopHeader from './header';
import shopFooter from './footer';
import gettingStarted from '@/components/common/gettingStarted';
import commonMixin from '@/components/mixin/common_mixin';
import {addLangStyle} from '@/utils/common';

export default {
  name: 'shopmain',
  data() {
    return {
      lang: '',
      searchBoxFixed: null
    };
  },
  mixins: [commonMixin],
  created() {
    this.$webSocket.initWebSocket();
    if (localStorage.getItem('lang')) {
      this.lang = localStorage.getItem('lang');
      addLangStyle();
    }
  },
  methods: {

  },
  computed: {
    fromListing () {
      return this.$store.state.fromListing
    }
  },
  components: {
    shopHeader,
    shopFooter,
    gettingStarted
  }
};
</script>

<style scoped>

</style>
