<template>
  <div class="shop-header" ref="shopHeaderContent" :class="headerTalg ? '' : 'header-bottom'">
    <!--头部广告位banner-->
    <div v-if="topAdvertisingImg && $store.state._isMultiSpecificationAttributeMerchant && !fromListing && !isAlibabaDistributor"
      class="top_banner_style" @click="toClearanceList">
      <img :src="topAdvertisingImg" alt="">
    </div>
    <!--头部导航菜单区域-->
    <headTop v-if="!fromListing"></headTop>
    <div v-if="!isAlibabaDistributor" class="header-conter" :class="navBarFixed ? 'fixed_header_conter' : ''">
      <!--通用的商城头部-->
      <shopHeader v-if="headerTalg"></shopHeader>
      <!--店铺的头部-->
      <store-header v-else></store-header>
    </div>
    <!--侧边广告位-->
    <sideAdvertisingSpace v-if="$store.state._isMultiSpecificationAttributeMerchant && !fromListing && !isAlibabaDistributor"
      :setTop="setTop"></sideAdvertisingSpace>
  </div>
</template>

<script>
import shopHeader from './common/shopHeader';
import storeHeader from './common/storeHeader';
import headTop from '@/components/layout/shopping/common/headTop';
import sideAdvertisingSpace from "@/components/common/sideAdvertisingSpace";
import Mixin from "@/components/mixin/common_mixin";
import {advertisingLinkAddress, isAlibabaDistributor, pageJump} from "@/utils/common";

export default {
  mixins: [Mixin],
  data() {
    return {
      supplierDomain: '',
      jumpTargetObj: null,
      setTop: null,
    };
  },
  props: {
    headerTalg: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
    baseInfo() {
      if (this.$store.state.baseInfo) {
        return this.$store.state.baseInfo.supplierDomain;
      } else {
        return '';
      }
    },
    topAdvertisingImg() {
      let advertisingList = this.$store.state.mallAdvertising;
      let imagePath = '';
      if (advertisingList && advertisingList.length > 0) {
        advertisingList.map((item) => {
          // 顶部广告位
          if (item.orientationType === 0) {
            if (item.status) {
              imagePath = item.ymsAdvertisementDetailBos[0].imagePath;
              this.jumpTargetObj = item.ymsAdvertisementDetailBos[0];
            }
          }
        });
      }
      return imagePath;
    },
    navBarFixed() {
      return this.$store.state.navBarFixed;
    },
    fromListing() {
      return this.$store.state.fromListing
    },
    isAlibabaDistributor() {
      return isAlibabaDistributor()
    }
  },
  components: {
    shopHeader,
    storeHeader,
    sideAdvertisingSpace,
    headTop
  },
  mounted() {
    this.dynamicTopHeight();
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    // 处理滚动吸顶
    handleScroll() {
      let v = this;
      if (v.$route.path !== '/shopList') {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        let talgHeight = v.$refs['shopHeaderContent'] ? v.$refs['shopHeaderContent'].offsetHeight + 15 : 15;
        if (scrollTop > talgHeight) {
          v.searchBoxFixed = true;
        } else {
          v.searchBoxFixed = false;
        }
        v.$store.commit('navBarFixed', v.searchBoxFixed);
      }
    },
    // 头部的高度
    dynamicTopHeight() {
      let v = this;
      v.$store.commit('navBarFixed', false);
      setTimeout(() => {
        v.$nextTick(() => {
          let talgHeight = v.$refs['shopHeaderContent'] ? v.$refs['shopHeaderContent'].offsetHeight + 15 : 15;
          v.setTop = talgHeight;
        });
      }, 2000);
    },
    // 商品中心
    commodityBtn() {
      if (this.supplierDomain) {
        let link = `${this.supplierDomain}/product-service/productCenter.html#/productGoods`;
        window.open(link, '_blank');
      }
    },
    // 我的收藏
    subscribeBtn() {
      pageJump('/distributor.html#/commodityList', '/yms-distributor-service', '_blank');
    },
    // 免费注册
    register() {
      pageJump('/login.html#/register', '/yms-shopping-service', '_self');
    },
    // 点击头部广告位的banner图
    toClearanceList() {
      let v = this;
      advertisingLinkAddress(v.jumpTargetObj);
    }
  },
  watch: {
    $route: {
      handler(data) {
        let v = this;
        v.dynamicTopHeight();
      },
      deep: true,
      immediate: true
    },
  }
};
</script>

<style lang="less" scoped>
@import "src/assets/styles/shopping/common";

.shop-header {
  width: 100%;
  background: #FFFFFF;
  margin-bottom: 15px;
  position: relative;

  .top_banner_style {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #FBDBE0;
    cursor: pointer;

    img {
      height: 100%;
    }
  }

  .nav-box {
    display: flex;
    border-bottom: 1px solid #d7d7d7;
    background-color: #F2F2F2;
    color: #333333;

    .header-menu {
      width: 1200px;
      margin: 0 auto;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .right-box {
        width: 60%;
        display: flex;
        justify-content: flex-end;
      }

      .welcome {
        padding-right: 5px;
      }

      .header-txt {
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #777777;
        display: flex;
        align-items: center;

        .user-icon {
          margin-right: 5px;
          font-weight: initial;
          color: #777777;
        }

        &:hover {
          color: @subject_color;

          .user-icon {
            color: @subject_color;
          }
        }
      }

      .line_item {
        color: #c3c1c1;
        margin: 0 10px;
      }
    }
  }

  .header-conter {
    width: 100%;
    background-color: #fff;
  }

  .fixed-header-conter {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1500);
  }

  .fixed_header_conter {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1500);
  }
}

.header-bottom {
  border-bottom: 2px solid @subject_color;
}

.lang-text {
  display: flex;
  width: 150px;
  line-height: 32px;
}

.languages .flag {
  height: 32px;
  width: 32px;
  background: url('../../../assets/images/flags.png') no-repeat;
}

.languages .zh-CN {
  background-position: 0 -32px;
}

.languages .en-US {
  background-position: 0 0;
}
</style>
