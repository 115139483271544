<template>
  <div class="shop-header-box">
    <!--logo和搜索框区域-->
    <div class="search-box">
      <!--logo图标-->
      <div class="logo-box">
        <img :src="logoUrl" style="cursor: pointer;vertical-align: middle" width="128" height="22" @click="toIndex"
          :alt="$t('key1000010')">
        <p class="logo-sub-desc">{{ $t('key1000063') }}</p>
      </div>
      <!--搜索框-->
      <div class="search-center">
        <div class="flex align-items">
          <Input v-model.trim="searchValue" class="search-input" :placeholder="$t('key1000061')"
            @on-enter="searchBtn">
            <Tooltip max-width="200" placement="top" class="ml5 cursor" transfer :content="$t('key1000062')" slot="suffix">
              <Upload
                ref="upload"
                name="files"
                :headers="headObj"
                :action="fileApi"
                :data="uploadData"
                :show-upload-list="false"
                :before-upload="handleBeforeUpload"
                :on-success="uploadSuccess"
                :format="['jpg', 'jpeg', 'png']"
                :max-size="20480"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize">
                <Icon type="ios-camera-outline" :size="28"/>
              </Upload>
            </Tooltip>
          </Input>
          <button class="search-btn" @click="searchBtn">
            <span class="text">{{ $t('key1000012') }}</span>
          </button>
        </div>
        <p class="hot-word" v-if="searchWord && searchWord.length">
          <span v-for="item in searchWord" @click="keySearch(item)">{{ item.name }}</span>
        </p>
      </div>
    </div>
    <!--所有分类区域-->
    <div class="classification-box" v-if="showBoxTalg || $route.path !=='/index'">
      <div class="classification-title" :class="showHover ? 'show-hover' : ''">
        <div class="flex align-items" style="width: 240px;">
          <Icon type="md-menu" size="26" color="#FD5425" class="ml12"/>
          <p class="title">{{ $t('key1000013') }}</p>
        </div>
        <!--分类悬浮框-->
        <classification v-show="showHover" :menuList="menuList" @toListBtn="toListBtn"></classification>
      </div>
      <!--商品参与活动的tab栏-->
      <activityTab></activityTab>
    </div>
  </div>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import classification from './classification';
import api from '@/api/';
import newsBox from "@/components/common/newsBox";
import Mixin from "@/components/mixin/common_mixin";
import activityTab from "@/components/common/activityTab";
import {getDomainSiteInfo} from "@/utils/common";

export default {
  name: 'shopHeader',
  mixins: [Mixin],
  data() {
    return {
      logoUrl: require('@/assets/images/logo.png'),
      currentIndex: 0,
      menuList: [],
      searchWord: [],
      searchValue: this.$route.query.searchValue || '',
      fileApi: api.erpCommon_uploadFiles + '?basePath=yms-core-service&action="uploadimage"',
      uploadData: {},
      imageSearch: {}
    };
  },
  computed: {
    showHover() {
      return this.$store.state.showHover;
    },
    // 是否隐藏频道tab栏
    showBoxTalg() {
      return this.$store.state.navBarFixed;
    },
    fromListing() {
      return this.$store.state.fromListing
    }
  },
  created() {
    this.getClassificationData();
    this.getSearchWord();
  },
  methods: {
    // 获取搜索词
    getSearchWord() {
      let v = this;
      let config = {
        headers: {
          'Language': v.languagesVal
        }
      }
      v.axios.get(api.get_ymsProductInfo_queryPopulerCategoryNameBySkuNumber, config).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          v.searchWord = data;
        }
      });
    },
    // 获取所有分类的数据
    getClassificationData() {
      let v = this;
      v.menuList = [];
      getDomainSiteInfo().then((list) => {
        if (list && list.length > 0) {
          let ymsSiteId = list[0].ymsSiteId;
          v.getCloudTreeData(api.post_ymsProductCategory_shopping_queryForExistsProduct, null, null, ymsSiteId).then((data) => {
            v.menuList = data;
          });
        }
      })
    },
    toIndex() {
      let v = this;
      v.$router.push({
        path: '/index',
        query: {
          from: v.fromListing ? 'listing' : undefined
        }
      });
    },
    // 选择分类菜单
    toListBtn(data) {
      let v = this;
      this.$router.push({
        path: '/shopList',
        query: {
          ymsProductCategoryId: data.ymsProductCategoryId,
          from: v.fromListing ? 'listing' : undefined
        }
      });
    },
    keySearch(item) {
      let v = this;
      this.$router.push({
        path: '/shopList',
        query: {
          ymsProductCategoryId: item.ymsProductCategoryId,
          from: v.fromListing ? 'listing' : undefined
        }
      });
    },
    // 模糊搜索
    searchBtn() {
      let obj = {};
      this.imageSearch = {};
      if (this.searchValue) {
        obj = {
          searchValue: this.searchValue
        };
      }
      // 当搜索的关键词为空，默认筛选全部商品
      else if (Object.keys(this.imageSearch).length <= 0) {
        obj = {
          activityTitle: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000069')
        }
      }
      this.$router.push({
        path: '/shopList',
        query: obj
      });
    },
    // 上传图片之前
    handleBeforeUpload(file) {
      let v = this;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64Data = reader.result;
          v.imageSearch['base64Url'] = base64Data;
          resolve(file);
        };
        // 读取文件内容，并将其转换为 Base64 编码数据
        reader.readAsDataURL(file);
      });
    },
    // 上传成功
    uploadSuccess(response, file, fileList, type) {
      let v = this;
      let data = response.datas[0];
      if (data) {
        v.searchValue = '';
        v.imageSearch.imgUrl = data;
        v.$router.push({
          path: '/shopList',
          query: {
            imgUrl: v.imageSearch.imgUrl,
            base64Url: v.imageSearch.base64Url
          }
        });
      }
    },
    // 校验文件格式
    handleFormatError(file) {
      let v = this;
      v.$Message.warning({
        content: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000064')} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000065')}`,
        duration: 5
      });
    },
    // 限制上传的文件不能超过20M
    handleMaxSize(file) {
      let v = this;
      v.$Message.error({
        content: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000066')}${file.name}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000067')} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000068')}`,
        duration: 5
      });
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.searchValue) {
        this.searchValue = this.$route.query.searchValue;
      } else {
        this.searchValue = '';
      }
    }
  },
  components: {
    classification,
    newsBox,
    activityTab
  }
};
</script>

<style lang="less" scoped>
@import "src/assets/styles/shopping/common";

.shop-header-box {
  width: 1200px;
  margin: 0 auto;

  .logo-box {
    width: 290px;
  }

  .logo-sub-desc {
    color: #3333337f;
    font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-top: 4px;
    font-size: 14px;
  }

  .search-box {
    padding: 35px 0 20px 0;
    display: flex;
    align-items: flex-end;

    .search-center {
      font-family: PingFang SC;
      width: 605px;

      .hot-word {
        font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        margin-top: 6px;
        color: #333333;
        opacity: 0.8;

        span {
          margin-right: 10px;
          cursor: pointer;
        }

        span:hover {
          color: @subject_color;
        }
      }

      .search-input {
        flex: 1;

        /deep/ .ivu-input {
          width: 533px;
          height: 40px;
          line-height: 40px;
          color: #666;
          box-shadow: none !important;
          outline: none;
          padding: 0 10px;
          font-size: 14px;
          border: 2px solid @subject_color;
          border-radius: 4px 0px 0px 4px;
        }

        /deep/ .ivu-input-suffix {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-right: 20px;
        }

        /deep/ .ivu-input-with-suffix {
          padding-right: 48px;
        }
      }

      .search-input::-webkit-input-placeholder {
        color: #AAAAAA;
      }

      .search-input:-moz-placeholder {
        color: #AAAAAA;
      }

      .search-input::-moz-placeholder {
        color: #AAAAAA;
      }

      .search-input:-ms-input-placeholder {
        color: #AAAAAA;
      }

      .search-btn {
        font-size: 14px;
        width: 72px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-weight: 400;
        color: #FFFFFF;
        background-color: @subject_color;
        border: none;
        outline: none;
        cursor: pointer;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }

  .classification-box {
    display: flex;
    align-items: center;
    height: 40px;

    .classification-title {
      height: 40px;
      display: flex;
      width: 280px;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      position: relative;
      background-color: #ffffff;
      color: @subject_color;

      .title {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: bold;
        padding-left: 12px;
      }
    }

    .show-hover {
      cursor: pointer;

      .menu-box {
        display: none;
        position: absolute;
        left: 0;
        top: 39px;
        z-index: 1000;

      }

      &:hover {
        .menu-box {
          display: flex !important;
        }
      }
    }
  }
}
</style>
